import React from 'react';
import ModelCanvas from '../components/ModelCanvas';
import Layout from '../components/Layout';
import Button from '../components/Button';

export default function indexPage() {
  const isBrowser = typeof window !== 'undefined';

  return (
    <Layout fullMenu>
      <article id="main">
        <header>
          <h2>Truncus Arteriosus</h2>
        </header>
        <section className="wrapper style5">
          <div className="inner model">
            <section>
              <div className="row">
                <div className="col-6 col-12-medium">
                  <h2>Truncus Arteriosus</h2>
                  <p>
                    is a rare form of congenital heart disease that presents at
                    birth. In this condition, the embryological structure known
                    as the truncus arteriosus fails to properly divide into the
                    pulmonary trunk and aorta. This results in one arterial
                    trunk arising from the heart and providing mixed blood to
                    the coronary arteries, pulmonary arteries, and systemic
                    circulation.
                    <br />
                    <p>
                      Treatment is with neonatal surgical repair, with the
                      objective of restoring a normal pattern of blood flow.
                    </p>
                  </p>
                </div>
                {isBrowser && (
                  <div className="col-6 col-12-medium">
                    <ModelCanvas
                      model_path={'/truncus-arteriosus.glb'}
                      camera_position={[5, 5, 5]}
                      light_intensity={0.4}
                      group_rotation={[0.2, -0.1, 0.4]}
                      group_position={[-2.0, -1.7, 1.4]}
                      group_scale={[30, 30, 30]}
                    />
                  </div>
                )}
              </div>
            </section>
            <div>
              <br />
              <ul className="actions stacked">
                <li>
                  <br />
                  <Button
                    buttonClass="button small primary"
                    url="https://biovoxel.tech/en/services/organ-models/"
                    text="Print 3D Model"
                    action="print"
                  />{' '}
                  <Button
                    buttonClass="button icon solid fa-coffee"
                    url="https://buymeacoff.ee/3DHearter"
                    text="^^"
                    action="donate"
                  />
                </li>
              </ul>
            </div>
          </div>
        </section>
      </article>
    </Layout>
  );
}
